import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Provider from "../components/Provider"
import Header from "../components/Header/Header"

import styled from "../components/styled"
import getColumnSize from "../utils/getColumnSize"
import { Container } from "../components/UI/Grid/Grid"
import { MainTitle, Description } from "../components/UI/Typography"
import Footer from "../components/Footer/Footer"
import Img, { FluidObject } from "gatsby-image"
import { Button } from "../components/UI"

const TitleContent = styled(MainTitle)`
  position: relative;
`

const TextContent = styled(Description)`
  margin-top: 20px;
  margin-bottom: 32px;
  max-width: 514px;
`

const Content = styled.div`
  width: ${getColumnSize(12)};
  margin-left: ${getColumnSize(0)};

  ${({ theme: { media } }) => media.above("large")} {
    width: ${getColumnSize(6)};
    margin-left: ${getColumnSize(1)};
  }
`

const Wrapper = styled.div`
  min-height: calc(100vh - 90px);
  display: flex;
  flex-direction: column;
  padding-top: 104px;
  padding-bottom: 60px;

  ${({ theme: { media } }) => media.above("large")} {
    flex-direction: row;
    padding-top: 202px;
    padding-bottom: 79px;
  }
`

const ImgContent = styled.div`
  width: ${getColumnSize(12)};
  ${({ theme: { media } }) => media.above("large")} {
    width: ${getColumnSize(5)};
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  max-width: 440px;
  margin: 32px auto 0;

  ${({ theme: { media } }) => media.above("large")} {
    margin: 0;
  }
`

interface NotFound {
  contentYaml: {
    notfound: {
      button: string
      button_url: string
      copy: string
      title: string
      image: {
        childImageSharp: {
          fluid: FluidObject
        }
      }
    }
  }
}

const NotFound: React.FC = () => {
  const data: NotFound = useStaticQuery(query)
  const content = data.contentYaml.notfound

  return (
    <Provider>
      <Header />
      <Container>
        <Wrapper>
          <Content>
            <TitleContent>{content.title}</TitleContent>
            <TextContent>{content.copy}</TextContent>
            <Button to={content.button_url}>{content.button}</Button>
          </Content>
          <ImgContent>
            <ImageWrapper>
              <Img fluid={content.image.childImageSharp.fluid} />
            </ImageWrapper>
          </ImgContent>
        </Wrapper>
      </Container>
      <Footer />
    </Provider>
  )
}

export const query = graphql`
  query NotFound {
    contentYaml(notfound: { button: { ne: null } }) {
      notfound {
        button
        button_url
        copy
        title
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

export default NotFound
